import { all, takeEvery, put, call, select } from 'redux-saga/effects'

// import { Alert, notification } from 'antd'
import { history } from '../../index'
import * as jwt from '../../services/auth'
import actions from './actions'
import toast, { Toaster } from 'react-hot-toast';

/*
|
*/
import {  message  } from 'antd';

const mapAuthProviders = {
  jwt: {
    login: jwt.login,
    loginSocial: jwt.loginSocial,
    forgetpassword: jwt.forgetpassword,
    register: jwt.register,
    currentAccount: jwt.currentAccount,
    logout: jwt.logout,
  },
}

export function* FORGET_PASSWORD({ payload }) {

  const  {primaryEmail}  = payload;

  yield put({
  type: 'user/SET_STATE',
  payload: {
    loading: true,
  },
})
const { authProvider: autProviderName } = yield select(state => state.settings)
const success = yield call(mapAuthProviders[autProviderName].forgetpassword, primaryEmail)
  if (success) {
    if(success.responseStatus === true)
    {
      toast.success( 'We sent you a password reset link to your email' , {
        duration: 50000,
      } );   
      yield history.push('/auth/login')
    } else {
      toast.error( 'This email does not exist' ); 
    }

  }
}

export function* LOGIN_SOCIAL({ payload }) {

  const { primaryEmail, accessToken, userID, provider } = payload;             
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const response = yield call(mapAuthProviders[autProviderName].loginSocial, primaryEmail, accessToken, userID)
  if (response) {
    if(response.responseStatus === true)
    {
      toast.success( 'You are logged in' );

      const { id, token, avatar, userRole, userSession   } = response;

      yield put({
        type: 'user/SET_STATE',
        payload: {
          id: id,
          userSession: userSession,
          role: userRole,
          authorized: true,
          loading: false,
          provider: provider
        },
      })      

    } else {
      toast.error( 'We could not find any account associated with your social profile.' );
    }
  } else {
    toast.error( 'We did not get any response from your social account! try again.' );
  }  
}

export function* LOGIN({ payload }) {

    /*
    | Open loading
    */
    const key = 'messageLogin';
    message.open({
      key,
      type: 'loading',
      content: 'Please wait..',
    });



  const { primaryEmail, password } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const response = yield call(mapAuthProviders[autProviderName].login, primaryEmail, password)

  if (response) {
    if(response.responseStatus === true)
    {
      // toast.success( 'You have successfully logged in!' );
      // notification.success({
      //   message: 'Logged In',
      //   description: 'You have successfully logged in!',
      // })     ;
      // window.location.href = '/#/dashboard/';
    const { id, userSession, token, avatar, userRole   } = response;
    yield put({
      type: 'user/SET_STATE',
      payload: {
        id: id,
        userSession: userSession,
        userRole: userRole,
        authorized: true,
        loading: false
      },
    })
   
    
    message.open({
      key,
      type: 'success',
      content: 'You are logged in!',

    });

    // yield history.push('/dashboard');

    //   notification.success({
    //     message: 'Logged In',
    //     description: 'You have successfully logged in!',
    //   })      
      
    } else {

      message.open({
        key,
        type: 'error',
        content: 'Username or password is not correct',

      });

      // toast.error( 'Username or password is not correct' );
      // notification.error({
      //   message: 'Error!',
      //   description: 'Username or password is not correct',
      // })       

    }
    // yield put({
    //   type: 'user/LOAD_CURRENT_ACCOUNT',
    // })
    // yield history.push('/')
    // notification.success({
    //   message: 'Logged In',
    //   description: 'You have successfully logged in!',
    // })
    
  }
  if (!response) {
    message.open({
      key,
      type: 'error',
      content: 'SOMETHING WENT WRONG! attention! This may block your IP address',

    });

    // toast.error( 'SOMETHING WENT WRONG! attention! This may block your IP address' );
    // alert();
    // yield put({
    //   type: 'user/SET_STATE',
    //   payload: {
    //     loading: false,
    //   },
    // })
  }
}













export function* REGISTER({ payload }) {
  const { firstname, lastname, primaryEmail, mobile, password  } = payload
  // yield put({
  //   type: 'user/SET_STATE',
  //   payload: {
  //     loading: true,
  //   },
  // })
  const { authProvider } = yield select(state => state.settings)
  const response = yield call(mapAuthProviders[authProvider].register, firstname, lastname, primaryEmail, mobile, password  )

  if( response )
  {
    if(response.responseStatus === true)
    {

    } else {


      yield put({
        type: 'user/REGISTER',
        data: response,          
        
      }) 
        // notification.error({
        //   message: 'Register Unsuccessful',
        //   description: response.responseMessage,
        // }) 
      
    }
  }
  // if (success) {
  //   yield put({
  //     type: 'user/LOAD_CURRENT_ACCOUNT',
  //   })
  //   yield history.push('/')
  //   notification.success({
  //     message: 'Succesful Registered',
  //     description: 'You have successfully registered!',
  //   })
  // }
  // if (!success) {
  //   yield put({
  //     type: 'user/SET_STATE',
  //     payload: {
  //       loading: false,
  //     },
  //   })
  // }
}

export function* LOAD_CURRENT_ACCOUNT() {

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider } = yield select(state => state.settings)



  const response = yield call(mapAuthProviders[authProvider].currentAccount)
  if (response) {

    const { id, primaryEmail, name, avatar, role } = response
    yield put({
      type: 'user/SET_STATE',
      payload: {
        id,
        name,
        primaryEmail,
        avatar,
        role,
        authorized: (response.status != 401?true:false),
      },
    })
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LOGOUT() {
  const { authProvider } = yield select(state => state.settings)
  const response = yield call(mapAuthProviders[authProvider].logout)

  if (response) {
    if(response.responseStatus === true)
    {
      toast.success( 'your session has been terminated' );
      // notification.success({
      //   message: response.message,
      //   description: 'you session has been terminated',
      // })        
      yield history.push('/auth/login')
      yield put({
        type: 'user/SET_STATE',
        payload: {
          id: '',
          name: '',
          role: '',
          primaryEmail: '',
          avatar: '',
          authorized: false,
          loading: false,
        },
      })   
      


    }
  }


}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.LOGIN_SOCIAL, LOGIN_SOCIAL),
    takeEvery(actions.FORGET_PASSWORD, FORGET_PASSWORD),
    takeEvery(actions.REGISTER, REGISTER),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ])
}
